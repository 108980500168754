import { Theme } from "@mui/material/styles";
import { SystemStyleObject } from "@mui/system";

const itemStyles = {
  image: (): SystemStyleObject<Theme> => ({
    "& img": {
      borderRadius: 0,
    },
  }),
  background: (
    _: Theme,
    { bgColor }: { bgColor?: string },
  ): SystemStyleObject<Theme> => ({
    "& > span": {
      display: "block !important",
      "&::before": {
        content: "''",
        position: "absolute",
        inset: 0,
        height: "100%",
        backgroundColor: bgColor,
        borderRadius: 0,
      },
    },
  }),
};

export default itemStyles;
