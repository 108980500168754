import { FC, useCallback, useEffect, useState } from "react";

import { SwiperSlide } from "swiper/react";
import type { SwiperProps } from "swiper/react";
import { Virtual } from "swiper/modules";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Masonry from "@mui/lab/Masonry";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Item from "./Item";

import Loader from "components/Loader";
import CustomButton from "components/CustomButton";
import Carousel from "components/Carousel";
import CarouselArrows from "components/Carousel/Arrows";

const LOAD_MORE_COUNT = 8;

type TweetsProps = {
  items: string[];
  defaultItems?: number;
  onPageLoaded?: () => void;
};

const Tweets: FC<TweetsProps> = ({
  items,
  defaultItems = LOAD_MORE_COUNT,
  onPageLoaded,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [tweetsCountToLoad, setTweetsCountToLoad] = useState(defaultItems);
  const [loadedTweetsCount, setLoadedTweetsCount] = useState(0);

  const isDesktopGroupLoading = tweetsCountToLoad > loadedTweetsCount;
  const config: SwiperProps = {
    spaceBetween: 16,
    slidesPerView: 1.2,
    virtual: {
      enabled: true,
      addSlidesAfter: 2,
      addSlidesBefore: 2,
    },
    modules: [Virtual],
    breakpoints: {
      600: {
        slidesPerView: 1.8,
      },
      900: {
        slidesPerView: 3.2,
      },
    },
  };

  useEffect(() => {
    if (
      onPageLoaded &&
      (tweetsCountToLoad === loadedTweetsCount ||
        (isMobile && loadedTweetsCount >= 1))
    ) {
      onPageLoaded();
    }
  }, [onPageLoaded, tweetsCountToLoad, loadedTweetsCount, isMobile]);

  const loadMore = useCallback(() => {
    setTweetsCountToLoad((count) => {
      if (count + LOAD_MORE_COUNT * 1.5 < items.length) {
        return count + LOAD_MORE_COUNT;
      }
      return items.length;
    });
  }, [items]);

  const onTweetLoaded = useCallback(() => {
    setLoadedTweetsCount((prevState) => prevState + 1);
  }, []);

  return isMobile ? (
    <Carousel config={config}>
      <span slot="container-start">
        <Container>
          <CarouselArrows />
        </Container>
      </span>
      <>
        {items.map((id) => {
          return (
            <SwiperSlide key={id} style={{ width: 250 }}>
              <Item id={id} onLoaded={onTweetLoaded} />
            </SwiperSlide>
          );
        })}
      </>
    </Carousel>
  ) : (
    <Container>
      <Masonry
        columns={4}
        spacing={2}
        defaultHeight={500}
        defaultColumns={4}
        defaultSpacing={2}
      >
        {items.slice(0, tweetsCountToLoad).map((id) => (
          <Box key={id}>
            <Item id={id} onLoaded={onTweetLoaded} />
          </Box>
        ))}
      </Masonry>

      {isDesktopGroupLoading && <Loader center />}

      {tweetsCountToLoad < items.length && (
        <Box display="flex" justifyContent="end">
          <CustomButton onClick={loadMore}>Load more</CustomButton>
        </Box>
      )}
    </Container>
  );
};

export default Tweets;
