import { FC, useCallback, useState } from "react";

import EastIcon from "@mui/icons-material/East";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useMediaQuery, useTheme } from "@mui/material";

import Tier4Modal from "sections/landingPage/Tier4Modal";
import Tier3Modal from "sections/landingPage/Tier3Modal";
import Heading from "sections/landingPage/Heading";

import CustomButton from "components/CustomButton";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomLink from "components/CustomLink";

import { APP_ROUTES } from "config/routes";

import styles from "./styles";

const Ecosystem: FC = () => {
  const [isTier3ModalOpened, setTier3ModalOpened] = useState(false);

  const openTier3Modal = useCallback(() => {
    setTier3ModalOpened(true);
  }, []);

  const closeTier3Modal = useCallback(() => {
    setTier3ModalOpened(false);
  }, []);

  const [isTier4ModalOpened, setTier4ModalOpened] = useState(false);

  const openTier4Modal = useCallback(() => {
    setTier4ModalOpened(true);
  }, []);

  const closeTier4Modal = useCallback(() => {
    setTier4ModalOpened(false);
  }, []);

  const ITEMS = [
    {
      title: "Create store",
      description:
        "Create a Store perfectly suited for you and list it on your site. Customize it to your needs, fit it to your style and sell your own NFTs. Choose the Store type: 'Production by Seller' or 'Production by Uniqly' and skyrocket your profits!",
      imageUrl: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/ecosystem/store.jpg`,
      redirectUrl: APP_ROUTES.STUDIO_CREATE,
      buttonText: "See more",
    },
    {
      title: "NFC Tags",
      description:
        "Add NFC Tag to any object and proove its autenticity with one tap of the phone. Take the phygital items to the next level!",
      imageUrl: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/ecosystem/extnd.jpg`,
      redirectUrl: APP_ROUTES.NFC_TAGS,
      buttonText: "See more",
    },
    {
      title: "Premium Store",
      description: (
        <>
          If your needs exceed 'Production by Seller' or 'Production by Uniqly'
          Stores available to create via{" "}
          <CustomLink href={APP_ROUTES.STUDIO_CREATE}>
            Store Generator
          </CustomLink>
          , Uniqly can create a custom solution for you.
        </>
      ),
      imageUrl: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/ecosystem/tier3.jpg`,
      onClick: openTier3Modal,
      buttonText: "See more",
    },
    {
      title: "Custom Solutions",
      description:
        "Uniqly offers end-to-end IT solutions. If you’ve got an out-of-the-box idea, we’re ready for it!",
      imageUrl: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/ecosystem/tier4.jpg`,
      onClick: openTier4Modal,
      buttonText: "See more",
    },
  ];

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box sx={{ mb: { xs: 4, md: 10, lg: 14 } }}>
      <Heading
        heading="Uniqly ecosystem"
        subheading="Browse through all of Uniqly services, choose the best option that suits your needs and enter a new digital era."
        boxProps={{
          sx: {
            mb: { xs: 6, md: 10 },
            textAlign: "center",
            maxWidth: 600,
            mx: "auto",
          },
        }}
      />

      <GridContainer spacing={0}>
        {ITEMS.map((item, i) => (
          <GridItem
            xs={12}
            md={6}
            lg={3}
            key={`${i}-item`}
            rowSpacing={4}
            sx={styles.itemWrapper}
          >
            <Box position="relative">
              <Box sx={(theme) => styles.box(theme, item.imageUrl)}>
                <Typography variant="h2" component="h3" sx={styles.title}>
                  {item.title}
                </Typography>

                {isDesktop && (
                  <Box
                    sx={styles.descriptionDesktop}
                    className="description-desktop"
                  >
                    <Typography paragraph mb={8}>
                      {item.description}
                    </Typography>
                    {item.buttonText && (
                      <CustomButton
                        href={item.onClick ? undefined : item.redirectUrl}
                        onClick={item.onClick || undefined}
                        target="_blank"
                        sx={{ ml: "auto" }}
                      >
                        {item.buttonText}
                        <EastIcon sx={{ ml: 1 }} />
                      </CustomButton>
                    )}
                  </Box>
                )}
              </Box>
              {!isDesktop && (
                <Box sx={{ mb: 8, mt: 4 }}>
                  <Typography paragraph>{item.description}</Typography>
                  <Box display="flex" justifyContent="end">
                    {item.buttonText && (
                      <CustomButton
                        href={item.onClick ? undefined : item.redirectUrl}
                        onClick={item.onClick || undefined}
                        target="_blank"
                        sx={{ width: { xs: "auto" } }}
                      >
                        {item.buttonText}
                        <EastIcon sx={{ ml: 1 }} />
                      </CustomButton>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </GridItem>
        ))}
      </GridContainer>

      <Tier3Modal isOpen={isTier3ModalOpened} handleClose={closeTier3Modal} />
      <Tier4Modal isOpen={isTier4ModalOpened} handleClose={closeTier4Modal} />
    </Box>
  );
};

export default Ecosystem;
