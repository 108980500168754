import { Theme } from "@mui/material/styles";
import { SystemStyleObject } from "@mui/system";

const contactStyles = {
  input: (theme: Theme): SystemStyleObject<Theme> => ({
    backgroundColor: "transparent",
    border: "none",
    borderRadius: 0,
    px: 0,
    color: theme.palette.grey[400],
    height: "auto",

    "&.Mui-error": {
      "& .MuiOutlinedInput-input": {
        borderColor: theme.palette.error.main,
      },
    },

    "& .MuiOutlinedInput-input": {
      fontSize: 22,
      lineHeight: 1.1,
      borderBottom: `2px solid ${theme.palette.grey[400]}`,
      py: 1,
      px: 0,

      [theme.breakpoints.up("lg")]: {
        fontSize: 32,
      },

      [theme.breakpoints.up("xl")]: {
        fontSize: 38,
      },
    },
  }),
  formControl: (): SystemStyleObject<Theme> => ({
    "& fieldset": {
      border: "none",
      borderRadius: 0,
    },
    "&:hover fieldset": {
      border: "none",
    },
  }),
  checkbox: (theme: Theme): SystemStyleObject<Theme> => ({
    color: theme.palette.text.primary,

    "&.Mui-checked": {
      color: theme.palette.text.primary,
    },
  }),
  errorText: (): SystemStyleObject<Theme> => ({
    fontSize: 16,
  }),
  errorColor: (theme: Theme): SystemStyleObject<Theme> => ({
    color: theme.palette.error.main,
  }),
  gridFullwidth: (): SystemStyleObject<Theme> => ({
    gridColumnStart: 1,
    gridColumnEnd: -1,
  }),
};

export default contactStyles;
