import { FC } from "react";
import { toast } from "react-toastify";

import { FormikProvider, useFormik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";

import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";

import { contactFormValidator } from "validators/client/contact";

import CustomButton from "components/CustomButton";
import CustomLink from "components/CustomLink";

import { CONTACT_TYPES } from "enums/contactTypes";

import { API_ROUTES, APP_ROUTES } from "config/routes";

import fetcher from "lib/fetchJson";

import styles from "./styles";

interface Contact {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
  consent: boolean;
}

const Contact: FC = () => {
  const formik = useFormik<Contact>({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      message: "",
      consent: false,
    },
    validationSchema: toFormikValidationSchema(contactFormValidator),
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      try {
        await fetcher(
          API_ROUTES.CONTACT,
          { ...values, type: CONTACT_TYPES.CONTACT },
          "POST",
        );
        toast.success("Form submitted successfully!");
        resetForm();
      } catch (err) {
        toast.error(err.data?.message ?? "Something went wrong");
      }
    },
  });

  return (
    <Box component="section" pb={4} maxWidth={950} mx="auto">
      <Box>
        <Typography
          variant="h1"
          component="h2"
          sx={{ mb: 3 }}
          textTransform="uppercase"
        >
          Contact us
        </Typography>
        <Typography color="grey.400" variant="h3" mb={4}>
          Choose experience and high quality of services
        </Typography>
      </Box>
      <FormikProvider value={formik}>
        <Box component="form" onSubmit={formik.handleSubmit}>
          <Box
            display="grid"
            sx={{
              gridTemplateColumns: { lg: "repeat(2, 1fr)" },
              gap: { xs: 2, xl: 4 },
              mb: { xs: 2, xl: 4 },
            }}
          >
            <FormControl fullWidth sx={styles.formControl}>
              <OutlinedInput
                autoComplete="off"
                sx={styles.input}
                onChange={formik.handleChange}
                value={formik.values.firstName}
                placeholder="First name"
                inputProps={{
                  id: "firstName",
                }}
                error={!!formik.errors.firstName}
              />
              {formik.errors.firstName && (
                <FormHelperText sx={styles.errorText} error>
                  {formik.errors.firstName}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth sx={styles.formControl}>
              <OutlinedInput
                autoComplete="off"
                sx={styles.input}
                onChange={formik.handleChange}
                value={formik.values.lastName}
                placeholder="Last name"
                inputProps={{
                  id: "lastName",
                }}
                error={!!formik.errors.lastName}
              />
              {formik.errors.lastName && (
                <FormHelperText sx={styles.errorText} error>
                  {formik.errors.lastName}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              fullWidth
              sx={[styles.formControl, styles.gridFullwidth]}
            >
              <OutlinedInput
                autoComplete="off"
                sx={styles.input}
                onChange={formik.handleChange}
                value={formik.values.email}
                placeholder="your@email.com"
                inputProps={{
                  id: "email",
                }}
                error={!!formik.errors.email}
              />
              {formik.errors.email && (
                <FormHelperText sx={styles.errorText} error>
                  {formik.errors.email}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              fullWidth
              sx={[styles.formControl, styles.gridFullwidth]}
            >
              <OutlinedInput
                autoComplete="off"
                sx={styles.input}
                onChange={formik.handleChange}
                value={formik.values.message}
                placeholder="your message"
                inputProps={{
                  id: "message",
                }}
                multiline
                maxRows={3}
                error={!!formik.errors.message}
              />
              {formik.errors.message && (
                <FormHelperText sx={styles.errorText} error>
                  {formik.errors.message}
                </FormHelperText>
              )}
            </FormControl>
          </Box>

          <Box mb={4}>
            <FormControlLabel
              value={formik.values.consent}
              control={
                <Checkbox
                  checked={formik.values.consent}
                  sx={[
                    styles.checkbox,
                    formik.errors.consent ? styles.errorColor : {},
                  ]}
                />
              }
              label={
                <Typography
                  variant="h4"
                  component="span"
                  sx={formik.errors.consent ? styles.errorColor : {}}
                >
                  I have read and accept{" "}
                  <CustomLink
                    href={APP_ROUTES.TERMS}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms & Conditions
                  </CustomLink>
                  {" and "}
                  <CustomLink
                    href={APP_ROUTES.PRIVACY}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </CustomLink>
                  *
                </Typography>
              }
              labelPlacement="end"
              onChange={(_: unknown, isChecked: boolean) => {
                formik.setFieldValue("consent", isChecked);
              }}
              sx={{ display: "flex" }}
            />
            {formik.errors.consent && (
              <FormHelperText sx={styles.errorText} error>
                {formik.errors.consent}
              </FormHelperText>
            )}
          </Box>

          <Box
            display="flex"
            sx={{ justifyContent: { xs: "end", md: "center" } }}
          >
            <CustomButton
              type="submit"
              disabled={formik.isSubmitting}
              isLoading={formik.isSubmitting}
            >
              Contact us
            </CustomButton>
          </Box>
        </Box>
      </FormikProvider>
    </Box>
  );
};

export default Contact;
