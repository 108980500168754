import { FC, useCallback } from "react";

import Image from "next/legacy/image";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { STORES } from "sections/landingPage/TopDrops/config";

import CarouselArrows from "components/Carousel/Arrows";
import CustomButton from "components/CustomButton";

import { APP_ROUTES } from "config/routes";

import styles from "./styles";

interface TopStoresDesktopProps {
  activeIndex: number;
  handleIndexChange: (index: number) => void;
}

const TopStoresDesktop: FC<TopStoresDesktopProps> = ({
  activeIndex,
  handleIndexChange,
}) => {
  const handlePrev = useCallback(() => {
    handleIndexChange(activeIndex === 0 ? STORES.length - 1 : activeIndex - 1);
  }, [activeIndex, handleIndexChange]);

  const handleNext = useCallback(() => {
    handleIndexChange(activeIndex === STORES.length - 1 ? 0 : activeIndex + 1);
  }, [activeIndex, handleIndexChange]);

  return (
    <Container sx={styles.container}>
      <CarouselArrows onPrev={handlePrev} onNext={handleNext} />
      <Box sx={styles.itemsWrapper}>
        {STORES.map((store, index) => (
          <CustomButton
            key={index}
            simple
            sx={(theme) =>
              styles.itemWrapper(theme, {
                isActive: index === activeIndex,
              })
            }
            onClick={() => {
              handleIndexChange(index);
            }}
          >
            <Box sx={styles.itemImage}>
              <Image src={store.bannerImage} layout="fill" />
            </Box>
            <Box
              sx={(theme) =>
                styles.itemText(theme, {
                  isActive: index === activeIndex,
                })
              }
            >
              <Typography variant="h4" align="left" color="primary">
                {store.name}
              </Typography>
              <CustomButton href={`${APP_ROUTES.STORES}/${store.url}`}>
                Explore store
              </CustomButton>
            </Box>
          </CustomButton>
        ))}
      </Box>
    </Container>
  );
};

export default TopStoresDesktop;
