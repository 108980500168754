import { FC } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Modal from "components/Modal";
import CustomButton from "components/CustomButton";
import CustomLink from "components/CustomLink";

import { CONTACT_UNIQLY_EMAIL } from "utils/consts";

interface Tier4ModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const Tier4Modal: FC<Tier4ModalProps> = ({ isOpen, handleClose }) => {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title="Custom Solutions">
      <Box textAlign="center" mt={2} maxWidth={600} marginX="auto">
        <Typography variant="body1" paragraph mb={3}>
          White-label solutions, unique projects, exclusive merches & drops and
          much more. Stores are great, but what if you’d like to create
          something completely different? The answer is: Yes we can!
        </Typography>
        <Typography variant="body1" paragraph mb={3}>
          <CustomLink href={`mailto:${CONTACT_UNIQLY_EMAIL}`}>
            Contact us
          </CustomLink>{" "}
          and we’ll find the best way to build something great together.
        </Typography>

        <Box display="flex" justifyContent="center">
          <CustomButton onClick={handleClose}>Close</CustomButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default Tier4Modal;
