import { FC } from "react";

import { useSwiper } from "swiper/react";

import Box from "@mui/material/Box";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { SxProps } from "@mui/material/styles";

import styles from "./styles";

interface CarouselArrowsProps {
  onPrev?: () => void;
  onNext?: () => void;
  sx?: SxProps;
}

const CarouselArrows: FC<CarouselArrowsProps> = ({ onPrev, onNext, sx }) => {
  const swiper = useSwiper();

  const handleLeft = () => {
    swiper?.slidePrev?.();
    onPrev?.();
  };

  const handleRight = () => {
    swiper?.slideNext?.();
    onNext?.();
  };

  return (
    <Box
      display="grid"
      justifyContent="end"
      gap={3}
      gridAutoFlow="column"
      mb={2}
      sx={sx}
    >
      <WestIcon onClick={handleLeft} fontSize="large" sx={styles.arrow} />
      <EastIcon onClick={handleRight} fontSize="large" sx={styles.arrow} />
    </Box>
  );
};
export default CarouselArrows;
