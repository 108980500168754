export const TESTIMONIALS = [
  "1764727966959906854",
  "1765437182666215670",
  "1761798870495850554",
  "1761801522336186505",
  "1761345210024399086",
  "1757501483795271696",
  "1752771498094559488",
  "1749587412324405519",
  "1745862843440734279",
  "1724879548037153128",
  "1713317341214502999",
  "1708107465252798607",
  "1703773087303455051",
  "1694627652668112933",
  "1691012997714427904",
  "1687154579467841538",
  "1686529530704363520",
  "1681626257597911040",
  "1678837886693539842",
];
