import { FC, ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";

import { selectAuthModalOpen, setAuthModalOpen } from "redux/slices/metamask";

import Header from "components/Header";
import Footer from "components/Footer";
import AuthModal from "components/AuthModal";
import ScrollToTop from "components/ScrollToTop";

type HomePageProps = {
  children: JSX.Element | JSX.Element[];
  headerDisclaimer?: ReactNode;
};

const HomePageLayout: FC<HomePageProps> = ({
  children,
  headerDisclaimer = "",
}) => {
  const dispatch = useDispatch();
  const isAuthModalOpen = useSelector(selectAuthModalOpen);

  const closeAuthModal = () => {
    dispatch(setAuthModalOpen(false));
  };

  return (
    <Box position="relative">
      <Header headerDisclaimer={headerDisclaimer} />

      <Box component="main">{children}</Box>

      {isAuthModalOpen && (
        <AuthModal isOpen={isAuthModalOpen} onClose={closeAuthModal} />
      )}
      <ScrollToTop />
      <Footer />
    </Box>
  );
};

export default HomePageLayout;
