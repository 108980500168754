import { Theme } from "@mui/material/styles";
import { SystemStyleObject } from "@mui/system";

const tabsSectionStyles = {
  header: (): SystemStyleObject<Theme> => ({
    display: "grid",
    justifyContent: "center",
  }),
  heading: (theme: Theme): SystemStyleObject<Theme> => ({
    mb: 1,
    textTransform: "uppercase",
    fontWeight: 800,
    textAlign: "center",
    color: theme.palette.secondary.main,
  }),
  subheading: (): SystemStyleObject<Theme> => ({
    fontWeight: 400,
    lineHeight: 1.7,
    textAlign: "center",
    mx: "auto",
    maxWidth: 800,
  }),
};

export default tabsSectionStyles;
