import { Theme } from "@mui/material/styles";
import { SystemStyleObject } from "@mui/system";

const headingStyles = {
  lineWrapper: (): SystemStyleObject<Theme> => ({
    "&.active svg": { animation: "dash 1s linear forwards" },
  }),
  line: (): SystemStyleObject<Theme> => ({
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: -1,
    color: "transparent",
    width: 303,
    height: 130,
    strokeDasharray: 1000,
    strokeDashoffset: 1000,

    "@keyframes dash": {
      to: {
        strokeDashoffset: 0,
      },
    },
  }),
};

export default headingStyles;
