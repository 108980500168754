export enum STORE_TYPES {
  STORE = "STORE",
  DROP = "DROP",
}

export enum STORE_VERSIONS {
  UNIQLY = "UNIQLY",
  PARTNER = "PARTNER",
  PHYSICAL = "PHYSICAL",
  DISPENSER = "DISPENSER",
}
