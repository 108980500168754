export const STORES = [
  {
    id: 0,
    name: "Cyberkongz",
    url: "cyberkongz2",
    mainImageDesktop: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/stores/cyberkongz.jpg`,
    desktopPoints: [
      {
        top: "32%",
        left: "30%",
        color: "#FFF",
        path: "/54/cyberkongz-logo-hoodie",
        name: "CyberKongz Logo Hoodie",
        imagePath: "/stores/cyberkongz-classic/products/3_front.png",
      },
      {
        top: "33%",
        left: "23%",
        color: "#FFF",
        path: "/52/ck-hoodie",
        name: "CK Hoodie",
        imagePath: "/stores/cyberkongz-classic/products/1_front.png",
      },
      {
        top: "34%",
        left: "39%",
        color: "#FFF",
        path: "/55/cyberkongz-logo-shirt",
        name: "CyberKongz Logo Shirt",
        imagePath: "/stores/cyberkongz-classic/products/4_front.png",
      },
      {
        top: "33%",
        left: "45%",
        color: "#FFF",
        path: "/56/ck-banana-shirt",
        name: "CK Banana Shirt",
        imagePath: "/stores/cyberkongz-classic/products/5_front.png",
      },
      {
        top: "48%",
        left: "53%",
        color: "#FFF",
        path: "/53/ck-banana-hoodie",
        name: "CK Banana Hoodie",
        imagePath: "/stores/cyberkongz-classic/products/2_front.png",
      },
    ],
    mobilePoints: [
      { top: "39%", left: "19%", color: "#FFF", path: "/52/ck-hoodie" },
      {
        top: "38%",
        left: "32%",
        color: "#FFF",
        path: "/55/cyberkongz-logo-shirt",
      },
      { top: "45%", left: "48%", color: "#FFF", path: "/53/ck-banana-hoodie" },
    ],
    mainImageMobile: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/stores/cyberkongz-mobile.jpg`,
    bannerImage: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/stores/cyberkongz-banner.png`,
  },
  {
    id: 1,
    name: "Peacevoid",
    url: "peaceful-groupies",
    desktopPoints: [
      {
        top: "32%",
        left: "30%",
        color: "#FFF",
        path: "/123/futura-children-shirt",
        name: "Futura Children Shirt",
        imagePath: "/stores/peaceful-groupies/products/3_front.png",
      },
      {
        top: "35%",
        left: "39%",
        color: "#FFF",
        path: "/122/salty-candy-shirt",
        name: "Salty Candy Shirt",
        imagePath: "/stores/peaceful-groupies/products/2_front.png",
      },
      {
        top: "34%",
        left: "44%",
        color: "#FFF",
        path: "/121/the-chip-shirt",
        name: "The Chip Shirt",
        imagePath: "/stores/peaceful-groupies/products/1_front.png",
      },
      {
        top: "48%",
        left: "53%",
        color: "#FFF",
        path: "/124/sprinkles-hoodie",
        name: "Sprinkles Hoodie",
        imagePath: "/stores/peaceful-groupies/products/4_front.png",
      },
    ],
    mainImageDesktop: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/stores/peacevoid.jpg`,
    mobilePoints: [
      {
        top: "39%",
        left: "17%",
        color: "#FFF",
        path: "/122/salty-candy-shirt",
      },
      { top: "39%", left: "30%", color: "#FFF", path: "/121/the-chip-shirt" },
      { top: "46%", left: "52%", color: "#FFF", path: "/124/sprinkles-hoodie" },
      { top: "57%", left: "58%", color: "#FFF", path: "/125/peacevoid-cap" },
    ],
    mainImageMobile: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/stores/peacevoid-mobile.jpg`,
    bannerImage: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/stores/peacevoid-banner.png`,
  },
  {
    id: 2,
    name: "Metakey",
    url: "the-metakey",
    mainImageDesktop: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/stores/metakey.jpg`,
    desktopPoints: [
      {
        top: "32%",
        left: "5%",
        color: "#FFF",
        path: "/141/the-keymaster",
        name: "The Keymaster",
        imagePath: "/stores/metakey/products/5_front.png",
      },
      {
        top: "33%",
        left: "15%",
        color: "#FFF",
        path: "/144/the-artifact",
        name: "The Artifact",
        imagePath: "/stores/metakey/products/8_front.png",
      },
      {
        top: "48%",
        left: "53%",
        color: "#FFF",
        path: "/145/the-genesis",
        name: "The Genesis",
        imagePath: "/stores/metakey/products/9_front.png",
      },
      {
        top: "35%",
        left: "39%",
        color: "#FFF",
        path: "/138/the-metakey",
        name: "The Metakey",
        imagePath: "/stores/metakey/products/2_front.png",
      },
      {
        top: "34%",
        left: "31%",
        color: "#FFF",
        path: "/143/the-ultimate",
        name: "The Ultimate",
        imagePath: "/stores/metakey/products/7_front.png",
      },
      {
        top: "33%",
        left: "45%",
        color: "#FFF",
        path: "/140/the-metakrew",
        name: "The Metakrew",
        imagePath: "/stores/metakey/products/4_front.png",
      },
      {
        top: "34%",
        left: "23%",
        color: "#FFF",
        path: "/139/the-asterius",
        name: "The Asterius",
        imagePath: "/stores/metakey/products/3_front.png",
      },
    ],
    mainImageMobile: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/stores/metakey-mobile.jpg`,
    mobilePoints: [
      {
        top: "38%",
        left: "10%",
        color: "#FFF",
        path: "/141/the-keymaster",
      },
      {
        top: "38%",
        left: "24%",
        color: "#FFF",
        path: "/144/the-artifact",
      },
      {
        top: "44%",
        left: "47%",
        color: "#FFF",
        path: "/145/the-genesis",
      },
    ],
    bannerImage: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/stores/metakey-banner.png`,
  },
  {
    id: 3,
    name: "Supducks",
    url: "supducks",
    mainImageDesktop: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/stores/supducks.jpg`,
    desktopPoints: [
      {
        top: "34%",
        left: "4%",
        color: "#FFF",
        path: "/154/bath-time-tee",
        name: "Bath Time Tee",
        imagePath: "/stores/sup-ducs/products/1_front.png",
      },
      {
        top: "48%",
        left: "53%",
        color: "#FFF",
        path: "/155/webb3d-feet-hoodie",
        name: "Webb3d Feet Hoodie",
        imagePath: "/stores/sup-ducs/products/2_front.png",
      },
      {
        top: "34%",
        left: "32%",
        color: "#FFF",
        path: "/157/get-off-tee",
        name: "Get Off Tee",
        imagePath: "/stores/sup-ducs/products/4_front.png",
      },
    ],
    mainImageMobile: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/stores/supducks-mobile.jpg`,
    mobilePoints: [
      {
        top: "38%",
        left: "9%",
        color: "#FFF",
        path: "/154/bath-time-tee",
      },
      {
        top: "46%",
        left: "47%",
        color: "#FFF",
        path: "/155/webb3d-feet-hoodie",
      },
      {
        top: "38%",
        left: "23%",
        color: "#FFF",
        path: "/157/get-off-tee",
      },
      { top: "57%", left: "58%", color: "#FFF", path: "/156/sup-hat" },
    ],
    bannerImage: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/stores/supducks-banner.png`,
  },
  {
    id: 4,
    name: "Pop Art Cats",
    url: "popartcats",
    mainImageDesktop: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/stores/popartcats.jpg`,
    desktopPoints: [
      {
        top: "48%",
        left: "53%",
        color: "#FFF",
        path: "/266/pop-art-cats-hoodie",
        name: "Pop Art Cats Hoodie",
        imagePath: "/stores/popartcats/products/1_front.png",
      },
    ],
    mainImageMobile: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/stores/popartcats-mobile.jpg`,
    mobilePoints: [
      {
        top: "46%",
        left: "52%",
        color: "#FFF",
        path: "/266/pop-art-cats-hoodie",
      },
    ],
    bannerImage: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/stores/popartcats-banner.png`,
  },
  {
    id: 5,
    name: "$HOOP",
    url: "hoop",
    mainImageDesktop: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/stores/chibi-dinos.jpeg`,
    desktopPoints: [
      {
        top: "32%",
        left: "30%",
        color: "#FFF",
        path: "/529/hoop-gaming-hoodie",
        name: "$HOOP Gaming Hoodie",
        imagePath: "/stores/chibidinos/products/7_front.png",
      },
      {
        top: "34%",
        left: "46%",
        color: "#FFF",
        path: "/524/gm-hoodie",
        name: "GM Hoodie",
        imagePath: "/stores/chibidinos/products/2_front.png",
      },
      {
        top: "34%",
        left: "42%",
        color: "#FFF",
        path: "/523/gm-nftee",
        name: "GM NFTee",
        imagePath: "/stores/chibidinos/products/1_front.png",
      },
      {
        top: "34%",
        left: "36%",
        color: "#FFF",
        path: "/527/pj-hoodie",
        name: "PJ Hoodie",
        imagePath: "/stores/chibidinos/products/5_front.png",
      },
      {
        top: "34%",
        left: "25%",
        color: "#FFF",
        path: "/528/hoop-gaming-tee",
        name: "$HOOP Gaming Tee",
        imagePath: "/stores/chibidinos/products/6_front.png",
      },
      {
        top: "34%",
        left: "20%",
        color: "#FFF",
        path: "/532/chibi-dinos-nftee",
        name: "Chibi Dinos NFTee",
        imagePath: "/stores/chibidinos/products/10_front.png",
      },
      {
        top: "34%",
        left: "15%",
        color: "#FFF",
        path: "/533/hoop-cd-jersey",
        name: "$HOOP CD Jersey",
        imagePath: "/stores/chibidinos/products/11_front.png",
      },
      {
        top: "34%",
        left: "10%",
        color: "#FFF",
        path: "/526/chibi-dinos-og-hoodie",
        name: "Chibi Dinos OG Hoodie",
        imagePath: "/stores/chibidinos/products/4_front.png",
      },
      {
        top: "48%",
        left: "53%",
        color: "#FFF",
        path: "/534/hoop-hoodie",
        name: "$HOOP Hoodie",
        imagePath: "/stores/chibidinos/products/12_front.png",
      },
      {
        top: "62%",
        left: "61%",
        color: "#FFF",
        path: "/530/hoop-cap",
        name: "$HOOP Cap",
        imagePath: "/stores/chibidinos/products/8_front.png",
      },
      {
        top: "63%",
        left: "44%",
        color: "#FFF",
        path: "/531/hoop-socks",
        name: "$HOOP Socks",
        imagePath: "/stores/chibidinos/products/9_front.png",
      },
    ],
    mainImageMobile: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/stores/chibi-dinos-mobile.jpeg`,
    mobilePoints: [
      {
        top: "46%",
        left: "52%",
        color: "#FFF",
        path: "/534/hoop-hoodie",
      },
      {
        top: "38%",
        left: "22%",
        color: "#FFF",
        path: "/527/pj-hoodie",
      },
      {
        top: "38%",
        left: "10%",
        color: "#FFF",
        path: "/529/hoop-gaming-hoodie",
      },
      {
        top: "38%",
        left: "33%",
        color: "#FFF",
        path: "/523/gm-nftee",
      },
      {
        top: "58%",
        left: "57%",
        color: "#FFF",
        path: "/530/hoop-cap",
      },
      {
        top: "60%",
        left: "36%",
        color: "#FFF",
        path: "/531/hoop-socks",
      },
    ],
    bannerImage: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/stores/chibi-dinos-banner.png`,
  },
];
