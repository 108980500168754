import { useInfiniteQuery, useQueryClient } from "react-query";

import { minutesToMilliseconds } from "date-fns";

import { STORE_TYPES } from "enums/storeTypes";

import { API_ROUTES } from "config/routes";

import { mapURLSearchParams } from "utils/query";

import { Client } from "services/store/Store.interface";

import fetcher from "lib/fetchJson";

interface UseClientProps {
  type?: STORE_TYPES;
  limit?: number;
  additionalFilters?: Record<string, string | boolean>;
}

const useStoresList = (props: UseClientProps) => {
  const queryClient = useQueryClient();
  const { type, limit = 6, additionalFilters } = props || {};

  const {
    fetchNextPage,
    hasNextPage,
    data,
    isLoading,
    isFetching,
    isStale,
    isRefetching,
  } = useInfiniteQuery(
    ["stores", { type, limit, ...additionalFilters }],
    async ({ pageParam = 1 }) => {
      const params = mapURLSearchParams({
        limit,
        page: pageParam,
        ...(type ? { "filters[type]": type } : {}),
        ...(additionalFilters || {}),
        "orderBy[publishDate]": "desc",
      });

      const response = await fetcher<{
        data: Client[];
        page: number;
        limit: number;
        totalPages: number;
        total: number;
      }>(`${API_ROUTES.STORES}?${params}`);

      return {
        items: response.data,
        totalPages: response.totalPages,
        nextPage: (pageParam < response.totalPages
          ? pageParam + 1
          : undefined) as number,
      };
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
      staleTime: minutesToMilliseconds(15),
    },
  );

  if (isRefetching && isStale) {
    queryClient.resetQueries({ queryKey: "stores" });
  }

  const items = data?.pages.reduce<Client[]>((acc, page) => {
    return [...acc, ...page.items];
  }, []);

  return {
    items: items || [],
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
  };
};

export default useStoresList;
