import { FC } from "react";

import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const LineIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    width="373"
    height="131"
    viewBox="0 0 373 131"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.35287 109.055C7.50845 134.658 95.2795 135.781 197.395 111.564C299.51 87.3465 377.301 46.9597 371.146 21.3573C364.99 -4.24513 277.219 -5.36813 175.104 18.849"
      stroke="url(#paint0_linear_5736_25597)"
      strokeWidth="2"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5736_25597"
        x1="197.395"
        y1="111.564"
        x2="175.391"
        y2="18.7808"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00323169" stopColor="#FFAB49" />
        <stop offset="1" stopColor="#9B5E15" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

export default LineIcon;
