import { FC } from "react";

import { SwiperSlide } from "swiper/react";
import type { SwiperProps } from "swiper/react";

import EastIcon from "@mui/icons-material/East";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import Heading from "sections/landingPage/Heading";
import useStoresList from "sections/storesPage/useStoresList";

import Carousel from "components/Carousel";
import StoreTile from "components/StoreTile";
import Loader from "components/Loader";
import CustomButton from "components/CustomButton";
import CarouselArrows from "components/Carousel/Arrows";

import { STORE_TYPES } from "enums/storeTypes";

import { APP_ROUTES } from "config/routes";

const LIMIT = 10;

const TopStores: FC = () => {
  const { items: clients, isLoading } = useStoresList({
    limit: LIMIT,
    type: STORE_TYPES.STORE,
  });

  const config: SwiperProps = {
    spaceBetween: 16,
    rewind: true,
    touchEventsTarget: "container",
    breakpoints: {
      0: {
        slidesPerView: 1.5,
      },
      600: {
        slidesPerView: 2,
      },
      900: {
        slidesPerView: 3.5,
      },
      1200: {
        slidesPerView: 5,
      },
      1536: {
        slidesPerView: 6,
      },
    },
  };

  return (
    <Box sx={{ mb: { xs: 12, lg: 14 } }}>
      <Container>
        <Heading
          heading="Stores"
          subheading="Explore phygital NFT Stores of our collaborators and take your shopping experience to the next level!"
          boxProps={{
            sx: {
              mb: { xs: 6, md: 8 },
              textAlign: "center",
              maxWidth: 600,
              mx: "auto",
            },
          }}
        />
      </Container>

      {isLoading ? (
        <Box width="100%">
          <Loader />
        </Box>
      ) : (
        <Carousel config={config}>
          <span slot="container-start">
            <Container>
              <CarouselArrows />
            </Container>
          </span>
          <>
            {clients.map((client, index) => {
              return (
                <SwiperSlide key={index}>
                  <StoreTile client={client} />
                </SwiperSlide>
              );
            })}
          </>
        </Carousel>
      )}

      <Container>
        <Box display="grid" justifyContent="end" sx={{ mt: { xs: 2, lg: 4 } }}>
          <CustomButton href={`${APP_ROUTES.STORES}`}>
            View more
            <EastIcon sx={{ ml: 1 }} />
          </CustomButton>
        </Box>
      </Container>
    </Box>
  );
};

export default TopStores;
