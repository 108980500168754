import Image from "next/image";

import { FC } from "react";

import Box, { BoxProps } from "@mui/material/Box";

import Heading from "sections/nfcTagsPage/Heading";

import ImageNext from "components/ImageNext";

import styles from "./styles";

interface ProductSectionProps {
  boxProps?: BoxProps;
}

const ProductSection: FC<ProductSectionProps> = ({ boxProps }) => {
  return (
    <Box component="section" display="grid" {...boxProps}>
      <Heading
        heading="Product"
        subheading="Comprehensive implementation of the Web3 solutions for your brand"
      />

      <Box sx={styles.contentWrapper}>
        <ImageNext
          src={`${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/nfcTagsPage/product/background.png`}
          width={1183}
          height={872}
          alt=""
          style={{
            width: "100%",
            objectFit: "contain",
            height: "auto",
          }}
        />

        <Box sx={styles.imagesWrapper}>
          <Image
            src={`${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/nfcTagsPage/product/t161_jumper.png`}
            width={1440}
            height={1586}
            alt=""
            style={{ width: "auto", objectFit: "contain", height: "100%" }}
          />
          <Image
            src={`${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/nfcTagsPage/product/connectors.svg`}
            width={581}
            height={1240}
            alt=""
            style={{
              width: "42%",
              objectFit: "contain",
              position: "absolute",
              bottom: "2%",
              left: "50%",
              transform: "translateX(-50%)",
              height: "auto",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: "5%",
              left: "49%",
              transform: "translateX(-50%)",
              width: "38%",
            }}
          >
            <Box
              component="video"
              src={`${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/nfcTagsPage/product/render.mp4`}
              autoPlay
              loop
              muted
              playsInline
              sx={{
                width: "63%",
                height: "auto",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
            <Image
              src={`${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/nfcTagsPage/product/frame.png`}
              width={347}
              height={596}
              alt=""
              style={{
                width: "100%",
                objectFit: "contain",
                height: "auto",
                position: "relative",
                zIndex: 10,
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductSection;
