import { FC, useRef } from "react";

import Box, { BoxProps } from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import LineIcon from "components/Icons/Line";

import useIntersectionObserver from "hooks/useIntersectionObserver";

import styles from "./styles";

interface HeadingProps {
  boxProps?: BoxProps;
  heading?: string;
  subheading?: string;
}

const ACTIVE_CLASS = "active";

const Heading: FC<HeadingProps> = ({ boxProps, heading, subheading }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const entry = useIntersectionObserver(ref, {
    rootMargin: "0px 0px -30% 0px",
  });

  if (entry?.isIntersecting && !ref.current?.classList.contains(ACTIVE_CLASS)) {
    ref.current?.classList.add(ACTIVE_CLASS);
  }

  return (
    <Box {...boxProps} position="relative">
      {heading && (
        <Typography
          fontWeight="600"
          variant="h2"
          sx={{ mb: { xs: 1, md: 2 }, textTransform: "uppercase" }}
        >
          {heading}
        </Typography>
      )}
      {subheading && (
        <Typography fontWeight="400" variant="h3">
          {subheading}
        </Typography>
      )}
      <Box ref={ref} sx={styles.lineWrapper}>
        <LineIcon sx={styles.line} />
      </Box>
    </Box>
  );
};

export default Heading;
