import { FC } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Modal from "components/Modal";
import CustomButton from "components/CustomButton";
import CustomLink from "components/CustomLink";

import { CONTACT_UNIQLY_EMAIL } from "utils/consts";

interface Tier3ModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const Tier3Modal: FC<Tier3ModalProps> = ({ isOpen, handleClose }) => {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title="Premium Store">
      <Box textAlign="center" mt={2} maxWidth={600} marginX="auto">
        <Typography variant="body1" paragraph mb={3}>
          Special designs, great ideas, unique solutions and much more. Nothing
          is impossible.
        </Typography>
        <Typography variant="body1" paragraph mb={3}>
          If you’d like to have 3D mockups of your products, add scannable NFC
          tags to the physical products, get an option to redeem items to
          Decentraland or need promotional materials to support your Store,
          Premium Stores cover all of these.
        </Typography>
        <Typography variant="body1" paragraph mb={3}>
          If you’d like to create a Premium Store,{" "}
          <CustomLink href={`mailto:${CONTACT_UNIQLY_EMAIL}`}>
            contact us
          </CustomLink>
          .
        </Typography>

        <Box display="flex" justifyContent="center">
          <CustomButton onClick={handleClose}>Close</CustomButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default Tier3Modal;
