import { Theme } from "@mui/material/styles";
import { SystemStyleObject } from "@mui/system";

const hexagonStyles = {
  wrapper: (theme: Theme): SystemStyleObject<Theme> => ({
    display: "grid",
    filter: `drop-shadow(0px 5px 5px ${theme.palette.background.paper})`,

    "&:hover": {
      ".hexagon:nth-of-type(2)": {
        transform: "scale(1.3)",
      },
      ".hexagon:nth-of-type(3)": {
        transform: "scale(1.6)",
      },
    },
  }),
  hexagon: (
    theme: Theme,
    { size, stroke, color }: { size: number; stroke: number; color: string },
  ): SystemStyleObject<Theme> => {
    return {
      gridColumn: 1,
      gridRow: 1,
      width: size,
      height: size * 1.1547,
      position: "relative",
      backgroundColor: color,
      transition: theme.transitions.create(["transform"]),
      clipPath: `polygon(
      50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%, 50% 0%,
      50% ${stroke}%, ${stroke}% ${25 + stroke / 1.7}%, ${stroke}% ${
        75 - stroke / 1.7
      }%, 50% ${100 - stroke}%, ${100 - stroke}% ${75 - stroke / 1.7}%, ${
        100 - stroke
      }% ${25 + stroke / 1.7}%, 50% ${stroke}%
    )`,
    };
  },
};

export default hexagonStyles;
