import { Theme } from "@mui/material/styles";
import { SystemStyleObject } from "@mui/system";

const heroStyles = {
  wrapper: (): SystemStyleObject<Theme> => ({
    position: "relative",
    height: "100vh",

    minHeight: 500,
    maxHeight: "min(1000px, 100%)",
    mx: "auto",
  }),
  overlay: (theme: Theme): SystemStyleObject<Theme> => ({
    position: "absolute",
    content: "''",
    zIndex: 1,
    width: "100%",
    height: "100%",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    pointerEvents: "none",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    "&::before": {
      content: "''",
      position: "absolute",
      zIndex: 1,
      width: "100%",
      left: 0,
      height: "40%",
      pointerEvents: "none",
      bottom: 0,
      backgroundImage: `linear-gradient(0deg, ${theme.palette.background.default}, transparent)`,
    },
  }),
  textHighlight: (theme: Theme): SystemStyleObject<Theme> => ({
    color: theme.palette.secondary.main,
  }),
  video: (): SystemStyleObject<Theme> => ({
    minWidth: "100%",
    width: "100%",
    objectFit: "cover",
    pointerEvents: "none",
  }),
  box: (): SystemStyleObject<Theme> => ({
    position: "absolute",
    bottom: 80,
    left: 0,
    display: "flex",
    flexDirection: "column",
    gap: { xs: 3, md: 4.5 },
  }),
  heading: (theme: Theme): SystemStyleObject<Theme> => ({
    fontWeight: 600,

    [theme.breakpoints.up("xs")]: {
      fontSize: 34,
      lineHeight: 1.1,
    },

    [theme.breakpoints.up("sm")]: {
      fontSize: 38,
      lineHeight: 1.2,
    },

    [theme.breakpoints.up("md")]: {
      fontSize: 48,
      maxWidth: "82%",
    },

    [theme.breakpoints.up("lg")]: {
      fontSize: 48,
      lineHeight: 1.3,
      maxWidth: "65%",
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: "64%",
    },
  }),
};

export default heroStyles;
