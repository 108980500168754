import { Theme } from "@mui/material/styles";
import { alpha, SystemStyleObject } from "@mui/system";

const BOX_WIDTH_TABLET = 100;
const BOX_WIDTH_DESKTOP = 150;

const topStoresDesktopStyles = {
  container: (): SystemStyleObject<Theme> => ({
    display: "flex",
    flexDirection: "column",
    position: "relative",
    zIndex: 1,
    alignItems: "end",
  }),
  itemsWrapper: (): SystemStyleObject<Theme> => ({
    display: "flex",
    gap: 1,
  }),
  itemWrapper: (
    theme: Theme,
    { isActive }: { isActive: boolean },
  ): SystemStyleObject<Theme> => ({
    display: "grid",
    backgroundColor: "none",
    border: "none",
    transition: theme.transitions.create(["width"], {
      easing: "linear",
      duration: 100,
    }),
    overflow: "hidden",
    position: "relative",

    height: BOX_WIDTH_TABLET,
    width: isActive ? BOX_WIDTH_TABLET * 3.28 : BOX_WIDTH_TABLET,

    [theme.breakpoints.up("lg")]: {
      height: BOX_WIDTH_DESKTOP,
      width: isActive ? BOX_WIDTH_DESKTOP * 3.28 : BOX_WIDTH_DESKTOP,
    },

    "&:hover, &:focus, &:active": {
      boxShadow: "none",
    },
  }),
  itemImage: (theme: Theme): SystemStyleObject<Theme> => ({
    "& > span": {
      width: `${BOX_WIDTH_TABLET * 3.28}px !important`,

      [theme.breakpoints.up("lg")]: {
        width: `${BOX_WIDTH_DESKTOP * 3.28}px !important`,
      },
    },
  }),
  itemText: (
    theme: Theme,
    { isActive }: { isActive: boolean },
  ): SystemStyleObject<Theme> => ({
    p: 1,
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundImage: `linear-gradient(0deg, ${alpha(
      theme.palette.background.default,
      0.6,
    )}, ${alpha(theme.palette.background.default, 0)})`,

    opacity: isActive ? 1 : 0,
    display: "flex",
    alignItems: "end",
    justifyContent: "space-between",

    transition: theme.transitions.create(["opacity"], {
      easing: "linear",
      duration: 100,
    }),
  }),
};

export default topStoresDesktopStyles;
