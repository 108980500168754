import { FC, useCallback, useState } from "react";
import { useSelector } from "react-redux";

import EastIcon from "@mui/icons-material/East";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useMediaQuery, useTheme } from "@mui/material";

import { STORES } from "./config";

import { selectChainId } from "redux/slices/metamask";

import Heading from "sections/landingPage/Heading";
import TopDropsMobile from "sections/landingPage/TopDropsMobile";
import TopDropsDesktop from "sections/landingPage/TopDropsDesktop";

import Hexagon from "components/Hexagon";
import CustomLink from "components/CustomLink";
import Loader from "components/Loader";
import Image from "components/Image";
import CustomButton from "components/CustomButton";

import { APP_ROUTES } from "config/routes";

import { getEthereumChainId } from "utils/blockchain";

import styles from "./styles";

const TopDrops: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const chainId = useSelector(selectChainId);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleIndexChange = useCallback(
    (index: number) => {
      if (index === activeIndex) return;
      if (index >= STORES.length) {
        setActiveIndex(0);
        return;
      }
      setActiveIndex(index);
    },
    [activeIndex],
  );

  return (
    <Box sx={{ mb: { xs: 12, lg: 14 } }}>
      <Container>
        <Heading
          heading="Premium Collections"
          subheading="Exclusive, time-limited collections of our top partners"
          boxProps={{
            sx: { mb: { xs: 6, lg: 19 }, textAlign: "center" },
          }}
        />
      </Container>

      <Box sx={styles.mainImageWrapper}>
        <Box
          position="relative"
          sx={{ transform: { lg: "translateY(-50px)" } }}
        >
          <Box sx={styles.loader}>
            <Loader />
          </Box>
          <Box sx={styles.imageWrapper}>
            {STORES.map((item) => (
              <Box
                key={item.id}
                sx={(theme) =>
                  styles.image(theme, { isActive: item.id === activeIndex })
                }
              >
                <Image
                  width={isMobile ? 975 : 1920}
                  height={isMobile ? 1425 : 1029}
                  quality={100}
                  src={isMobile ? item.mainImageMobile : item.mainImageDesktop}
                  alt=""
                  priority={item.id === 0}
                  loading={item.id === 0 ? "eager" : "lazy"}
                />
              </Box>
            ))}
            {isMobile && (
              <CustomButton
                href={`${APP_ROUTES.STORES}/${STORES[activeIndex]?.url}`}
                sx={styles.exploreButton}
              >
                Explore store
              </CustomButton>
            )}
          </Box>

          {isMobile
            ? STORES[activeIndex]?.mobilePoints.map(
                ({ path, color, ...sx }, index) => (
                  <CustomLink
                    key={index}
                    href={`${APP_ROUTES.STORE_PRODUCT}${path}?chainId=${
                      chainId || getEthereumChainId()
                    }`}
                  >
                    <Hexagon
                      sx={[sx, styles.hexagon]}
                      size={isMobile ? 15 : 25}
                      stroke={isMobile ? 20 : 13}
                      color={color}
                    />
                  </CustomLink>
                ),
              )
            : STORES[activeIndex]?.desktopPoints.map(
                ({ path, color, imagePath, name, ...sx }, index) => (
                  <Tooltip
                    key={index}
                    PopperProps={{
                      sx: (theme) => ({
                        "& .MuiTooltip-tooltip": {
                          p: 2,
                          backgroundColor: theme.palette.background.default,
                          boxShadow: "-8px 0px 16px rgba(0, 0, 0, 0.5)",
                        },
                      }),
                    }}
                    title={
                      <Box width={210}>
                        <Image
                          src={`${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}${imagePath}`}
                          width={466}
                          height={540}
                          objectFit="cover"
                        />
                        <Typography variant="h5" mb={0.5}>
                          Name
                        </Typography>
                        <Typography variant="h4" mb={2}>
                          {name}
                        </Typography>
                        <CustomButton
                          fullWidth
                          href={`${APP_ROUTES.STORE_PRODUCT}${path}?chainId=${
                            chainId || getEthereumChainId()
                          }`}
                        >
                          Go to item
                          <EastIcon sx={{ ml: 1 }} />
                        </CustomButton>
                      </Box>
                    }
                  >
                    <Box sx={[sx, styles.hexagon]}>
                      <Hexagon
                        size={isMobile ? 15 : 25}
                        stroke={isMobile ? 20 : 13}
                        color={color}
                      />
                    </Box>
                  </Tooltip>
                ),
              )}
        </Box>
      </Box>

      <Box sx={styles.container}>
        {isMobile ? (
          <TopDropsMobile
            activeIndex={activeIndex}
            handleIndexChange={handleIndexChange}
          />
        ) : (
          <TopDropsDesktop
            activeIndex={activeIndex}
            handleIndexChange={handleIndexChange}
          />
        )}
      </Box>
      <Container>
        <Box
          sx={{
            mt: { xs: 3, lg: 10 },
            textAlign: "center",
          }}
        >
          <CustomButton
            href={APP_ROUTES.STORES}
            sx={{
              height: 60,
              width: 240,
              fontSize: 18,
            }}
          >
            Explore all stores →
          </CustomButton>
        </Box>
      </Container>
    </Box>
  );
};

export default TopDrops;
