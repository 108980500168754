import { FC } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import styles from "./styles";

const Hero: FC = () => {
  return (
    <Box sx={[styles.wrapper, { mb: { xs: 3, lg: 6 } }]}>
      <Box sx={styles.overlay} />

      <Box
        component="video"
        src={`${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/hero/video.mp4`}
        autoPlay
        loop
        muted
        playsInline
        width="auto"
        height="100%"
        sx={styles.video}
      />
      <Container>
        <Box
          sx={{
            zIndex: 1,
            position: "relative",
            width: "100%",
            height: "100%",
            left: 0,
          }}
        >
          <Box sx={styles.box}>
            <Typography variant="h1" sx={styles.heading}>
              We enable{" "}
              <Box component="span" sx={styles.textHighlight}>
                creators, artists and brands
              </Box>{" "}
              to blend physical and digital experiences.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
