import Image from "next/image";

import { FC } from "react";

import { SwiperSlide } from "swiper/react";
import type { SwiperProps } from "swiper/react";
import { Autoplay } from "swiper/modules";

import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";

import Heading from "sections/landingPage/Heading";

import Carousel from "components/Carousel";

import styles from "./styles";

interface Logo {
  name: string;
  imageUrl: string;
  width: number;
  height: number;
  scale: number;
}

const LOGOS: Logo[] = [
  {
    name: "alphabit", //done
    imageUrl: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/partners/alphabit.png`,
    width: 480,
    height: 102,
    scale: 0.5,
  },
  {
    name: "chainlink", //done
    imageUrl: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/partners/chainlink.png`,
    width: 326,
    height: 100,
    scale: 0.5,
  },
  {
    name: "chilistudios", // done
    imageUrl: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/partners/chilistudios.png`,
    width: 424,
    height: 300,
    scale: 0.3,
  },
  {
    name: "curate", // done
    imageUrl: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/partners/curate.png`,
    width: 426,
    height: 102,
    scale: 0.5,
  },
  {
    name: "cyberkongz", // done
    imageUrl: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/partners/cyberkongz.png`,
    width: 376,
    height: 100,
    scale: 0.5,
  },
  {
    name: "decentraland", // done
    imageUrl: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/partners/decentraland.png`,
    width: 622,
    height: 100,
    scale: 0.3,
  },
  {
    name: "enjin", // done
    imageUrl: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/partners/enjin.png`,
    width: 322,
    height: 102,
    scale: 0.5,
  },
  {
    name: "future", // exists
    imageUrl: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/partners/future.png`,
    width: 2437,
    height: 477,
    scale: 0.1,
  },
  {
    name: "lazy lions", // done
    imageUrl: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/partners/lazylions.png`,
    width: 582,
    height: 102,
    scale: 0.3,
  },
  {
    name: "master ventures", // done
    imageUrl: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/partners/masterventures.png`,
    width: 346,
    height: 102,
    scale: 0.5,
  },
  {
    name: "over9000", // exists
    imageUrl: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/partners/over9k.png`,
    width: 500,
    height: 334,
    scale: 0.25,
  },
  {
    name: "phaver", // done
    imageUrl: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/partners/phaver.png`,
    width: 1024,
    height: 314,
    scale: 0.15,
  },
  {
    name: "pizza dao", // exists
    imageUrl: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/partners/pizzadao.png`,
    width: 489,
    height: 70,
    scale: 0.5,
  },
  {
    name: "poap", // done
    imageUrl: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/partners/poap.png`,
    width: 174,
    height: 200,
    scale: 0.5,
  },
  {
    name: "polygon", // done
    imageUrl: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/partners/polygon.png`,
    width: 324,
    height: 68,
    scale: 0.5,
  },
  {
    name: "rekt", // done
    imageUrl: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/partners/rekt.png`,
    width: 1448,
    height: 498,
    scale: 0.1,
  },
  {
    name: "rugradio", // done
    imageUrl: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/partners/rugradio.png`,
    width: 864,
    height: 552,
    scale: 0.25,
  },
  {
    name: "sandbox", // done
    imageUrl: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/partners/sandbox.png`,
    width: 306,
    height: 92,
    scale: 0.6,
  },
  {
    name: "sappy seals", //exists
    imageUrl: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/partners/sappy.png`,
    width: 824,
    height: 609,
    scale: 0.1,
  },
  {
    name: "virtus pro", // done
    imageUrl: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/partners/virtuspro.png`,
    width: 864,
    height: 102,
    scale: 0.25,
  },
  {
    name: "w3", // done
    imageUrl: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/partners/w3.png`,
    width: 256,
    height: 96,
    scale: 0.4,
  },
  {
    name: "woo",
    imageUrl: `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/pages/lp/partners/woo.png`,
    width: 176,
    height: 48,
    scale: 0.7,
  },
];

const PartnersCarousel: FC = () => {
  const theme = useTheme();
  const isBelowMd = useMediaQuery(theme.breakpoints.down("md"));
  const isBelowLg = useMediaQuery(theme.breakpoints.down("lg"));

  const slideWidth = isBelowMd ? 120 : isBelowLg ? 160 : 200;

  const config: SwiperProps = {
    spaceBetween: 30,
    loop: true,
    slidesPerView: "auto",
    modules: [Autoplay],
    grabCursor: false,
    autoplay: {
      delay: 4000,
    },
    allowTouchMove: false,
    breakpoints: {
      900: {
        spaceBetween: 40,
      },
      1200: {
        spaceBetween: 80,
      },
    },
  };

  return (
    <Box
      sx={{
        mb: { xs: 12, md: 14, lg: 20 },
        "& .swiper-wrapper": { alignItems: "center" },
      }}
    >
      <Container>
        <Heading
          heading="Partners & Collaborators"
          subheading="We’re proud to work with the most established brands in the Web3 world."
          boxProps={{
            sx: { mb: { xs: 6, md: 10, lg: 12 }, textAlign: "center" },
          }}
        />
      </Container>

      <Carousel config={config}>
        {LOGOS.map((logo) => (
          <SwiperSlide key={logo.name} style={{ width: slideWidth }}>
            <Box key={logo.name} sx={styles.carouselItem}>
              <Image
                src={logo.imageUrl}
                alt={logo.name}
                width={logo.width * logo.scale}
                height={logo.height * logo.scale}
                style={{ objectFit: "contain", maxWidth: "100%" }}
              />
            </Box>
          </SwiperSlide>
        ))}
      </Carousel>
    </Box>
  );
};
export default PartnersCarousel;
