import { Theme } from "@mui/material/styles";
import { SystemStyleObject } from "@mui/system";

const tabsSectionStyles = {
  contentWrapper: (): SystemStyleObject<Theme> => ({
    position: "relative",

    maxWidth: 850,
    width: "100%",
    mx: "auto",
  }),
  imagesWrapper: (): SystemStyleObject<Theme> => ({
    position: "absolute",
    top: "-28%",
    left: "50%",
    transform: "translateX(-50%)",
    height: "160%",
  }),
};

export default tabsSectionStyles;
