// eslint-disable-next-line import/named
import { Tweet } from "react-twitter-widgets";
import { FC, memo, useEffect, useState } from "react";

import Loader from "components/Loader";

interface TestimonialsItemProps {
  id: string;
  onLoaded: () => void;
}

const Item: FC<TestimonialsItemProps> = ({ id, onLoaded }) => {
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    if (isLoaded) {
      onLoaded();
    }
  }, [isLoaded, onLoaded]);

  const handleOnLoad = () => {
    setLoaded(true);
  };
  const handleOnError = () => {
    setLoaded(true);
    return <div>&nbsp;</div>;
  };

  return (
    <>
      <Tweet
        tweetId={id}
        onLoad={handleOnLoad}
        renderError={handleOnError}
        options={{
          lang: "en",
          theme: "dark",
          height: "auto",
          width: "100%",
        }}
      />
      {!isLoaded && <Loader />}
    </>
  );
};

export default memo(Item);
