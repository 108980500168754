import { FC } from "react";

import Box from "@mui/material/Box";
import { SxProps, Theme } from "@mui/material/styles";

import styles from "./styles";

interface HexagonIconProps {
  sx?: SxProps<Theme>;
  size: number;
  stroke: number;
  color: string;
}

const HexagonIcon: FC<HexagonIconProps> = ({
  sx = [],
  size,
  color,
  stroke,
}) => (
  <Box sx={[styles.wrapper, ...(Array.isArray(sx) ? sx : [sx])]}>
    <Box
      sx={[(theme) => styles.hexagon(theme, { size, color, stroke })]}
      className="hexagon"
    />
    <Box
      sx={[
        (theme) => styles.hexagon(theme, { size, color, stroke }),
        { opacity: 0.4 },
      ]}
      className="hexagon"
    />
    <Box
      sx={[
        (theme) => styles.hexagon(theme, { size, color, stroke }),
        { opacity: 0.2 },
      ]}
      className="hexagon"
    />
  </Box>
);

export default HexagonIcon;
