import { z } from "zod";

export const contactFormValidator = z.object({
  firstName: z.string({ required_error: "Field is required" }).trim(),
  lastName: z.string({ required_error: "Field is required" }).trim(),
  email: z
    .string({ required_error: "Field is required" })
    .trim()
    .email({ message: "Wrong email format" }),
  message: z.string({ required_error: "Field is required" }).trim(),
  consent: z
    .boolean()
    .refine((val) => !!val, { message: "Consent is required" }),
});
