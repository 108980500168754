import { FC, ReactNode } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import styles from "./styles";

interface HeadingProps {
  heading: string | ReactNode;
  subheading?: string | ReactNode;
}

const Heading: FC<HeadingProps> = ({ heading, subheading }) => {
  return (
    <Box sx={styles.header}>
      <Typography
        variant="h1"
        component="h2"
        sx={[styles.heading, { mb: !!subheading ? 2 : 6 }]}
      >
        {heading}
      </Typography>
      {subheading && (
        <Typography variant="h4" sx={[styles.subheading, { mb: 6 }]}>
          {subheading}
        </Typography>
      )}
    </Box>
  );
};

export default Heading;
