import { FC, useEffect, useState } from "react";

import isNumber from "lodash/isNumber";

import NextImage, { ImageProps } from "next/legacy/image";

import Box from "@mui/material/Box";

import Loader from "components/Loader";

import styles from "./styles";

interface ImageWrapperProps extends Omit<ImageProps, "alt"> {
  src: string;
  alt?: string;
  errorImg?: string;
  bgColor?: string | null;
  imageUnoptimized?: boolean;
  width?: number;
  height?: number;
}

const ImageWrapper: FC<ImageWrapperProps> = ({
  src,
  alt = "",
  errorImg = "",
  bgColor,
  imageUnoptimized = false,
  ...props
}) => {
  const [imgSrc, setSrc] = useState("");

  useEffect(() => {
    const onLoad = () => {
      setSrc(src);
    };

    const onError = () => {
      setSrc(errorImg);
    };

    const img = new Image();
    img.src = src;
    img.addEventListener("load", onLoad);
    img.addEventListener("error", onError);
    return () => {
      img.removeEventListener("load", onLoad);
      img.removeEventListener("error", onError);
    };
  }, [src, errorImg]);

  return imgSrc ? (
    <Box
      sx={[
        styles.image,
        (theme) => (bgColor ? styles.background(theme, { bgColor }) : {}),
      ]}
    >
      <NextImage
        loading="lazy"
        unoptimized={imageUnoptimized}
        {...props}
        alt={alt || imgSrc}
        src={imgSrc}
      />
    </Box>
  ) : (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        width: "100%",
        maxWidth: "100vw",
        ...(props.width &&
        props.height &&
        isNumber(props.width) &&
        isNumber(props.height)
          ? { aspectRatio: `${props.width / props.height}` }
          : {}),
      }}
    >
      <Loader center />
    </Box>
  );
};

export default ImageWrapper;
