import { Theme } from "@mui/material/styles";
import { alpha, SystemStyleObject } from "@mui/system";

const ecosystemStyles = {
  box: (theme: Theme, url: string): SystemStyleObject<Theme> => ({
    display: "flex",
    justifyContent: "end",
    flexDirection: "column",
    padding: 2,
    height: 300,
    backgroundImage: `linear-gradient(0deg, ${theme.palette.background.default}, transparent 30%, transparent), url(${url})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",

    [theme.breakpoints.up("md")]: {
      height: 400,

      "&::before": {
        content: "''",
        position: "absolute",
        width: "100%",
        left: 0,
        height: "100%",
        pointerEvents: "none",
        bottom: 0,
        backgroundColor: alpha(theme.palette.background.default, 0),
        transition: theme.transitions.create(["background-color"]),
      },
      "&:hover::before": {
        backgroundColor: alpha(theme.palette.background.default, 0.8),
      },
    },
  }),
  title: (theme: Theme): SystemStyleObject<Theme> => ({
    textTransform: "uppercase",
    width: "100%",
    position: "relative",

    [theme.breakpoints.up("xs")]: {
      textAlign: "left",
    },
  }),
  itemWrapper: (theme: Theme): SystemStyleObject<Theme> => ({
    "& .description-desktop": {
      transition: theme.transitions.create(["max-height"]),
      maxHeight: 0,
      pointerEvents: "none",

      "& button": {
        opacity: 0,
      },
    },
    "&:hover .description-desktop": {
      maxHeight: 400,
      pointerEvents: "auto",

      "& button": {
        opacity: 1,
      },
    },
  }),
  descriptionDesktop: (theme: Theme): SystemStyleObject<Theme> => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    pt: 1,

    [theme.breakpoints.up("md")]: {
      pt: 4,
    },
  }),
  titleContainer: (theme: Theme): SystemStyleObject<Theme> => ({
    alignSelf: "end",
    position: "relative",
    zIndex: 1,
    pb: 1,

    [theme.breakpoints.up("md")]: {
      pb: 4,
    },
  }),
};

export default ecosystemStyles;
