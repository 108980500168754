import dynamic from "next/dynamic";

import { useEffect } from "react";

import { NextPage } from "next";

import Container from "@mui/material/Container";

import Hero from "sections/landingPage/Hero";
import TopStores from "sections/landingPage/TopStores";
import TopDrops from "sections/landingPage/TopDrops";
import Ecosystem from "sections/landingPage/Ecosystem";
import Testimonials from "sections/landingPage/Testimonials";
import ProductSection from "sections/nfcTagsPage/ProductSection";
import Contact from "sections/landingPage/Contact";
import Layout from "sections/landingPage/Layout";
import PartnersCarousel from "sections/landingPage/PartnersCarousel";

const DiscoverySection = dynamic(
  import("sections/nfcTagsPage/DiscoverySection"),
  {
    ssr: false,
  },
);

const HomePage: NextPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  return (
    <Layout>
      <Hero />
      <TopDrops />
      <TopStores />
      <Container>
        <Ecosystem />
      </Container>
      <Container>
        <ProductSection
          boxProps={{
            mb: { xs: 12, md: 22, lg: 26 },
          }}
        />
        <DiscoverySection
          boxProps={{
            mb: { xs: 12, lg: 16 },
          }}
          isReadyToStart={true}
        />
      </Container>
      <PartnersCarousel />
      <Testimonials />
      <Container>
        <Contact />
      </Container>
    </Layout>
  );
};

export default HomePage;
