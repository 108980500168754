import { Theme } from "@mui/material/styles";
import { SystemStyleObject } from "@mui/system";

const carouselStyles = {
  arrow: (theme: Theme): SystemStyleObject<Theme> => ({
    cursor: "pointer",
    "&:hover": {
      filter: `drop-shadow(0px 0px 6px ${theme.palette.text.primary})`,
    },
  }),
};

export default carouselStyles;
