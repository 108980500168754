import { Theme } from "@mui/material/styles";
import { SystemStyleObject } from "@mui/system";

const topStoresStyles = {
  mainImageWrapper: (theme: Theme): SystemStyleObject<Theme> => ({
    position: "relative",
    width: "100%",
    maxWidth: 1536,
    mx: "auto",

    minHeight: 400,
    maxHeight: 700,
    overflow: "hidden",

    "&::before": {
      content: "''",
      position: "absolute",
      zIndex: 1,
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      pointerEvents: "none",
      backgroundImage: `linear-gradient(180deg,
        ${theme.palette.background.default} 0%, transparent 10%,
        transparent 90%, ${theme.palette.background.default} 100%)
      `,
    },
    [theme.breakpoints.up("lg")]: {
      maxHeight: "min(80vh, 700px)",
    },
  }),
  imageWrapper: (): SystemStyleObject<Theme> => ({
    display: "grid",
    "& > *": {
      gridColumn: 1,
      gridRow: 1,
    },
  }),
  container: (theme: Theme): SystemStyleObject<Theme> => ({
    [theme.breakpoints.up("md")]: {
      mt: -13,
    },

    [theme.breakpoints.up("lg")]: {
      mt: -17,
    },
  }),
  loader: (): SystemStyleObject<Theme> => ({
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  }),
  image: (
    theme: Theme,
    { isActive }: { isActive: boolean },
  ): SystemStyleObject<Theme> => ({
    ...(isActive
      ? {
          opacity: 1,
          transition: theme.transitions.create(["opacity"], {
            duration: 100,
          }),
        }
      : {
          opacity: 0,
          transition: theme.transitions.create(["opacity"], {
            duration: 100,
            delay: 100,
          }),
        }),
  }),
  hexagon: (): SystemStyleObject<Theme> => ({
    position: "absolute",
    zIndex: 1,
    p: 2,
  }),
  exploreButton: (): SystemStyleObject<Theme> => ({
    position: "absolute",
    top: "min(135vw, 650px)",
    right: 16,
    width: "max-content !important",
    zIndex: 10,
  }),
};

export default topStoresStyles;
