import { FC } from "react";

import { Swiper } from "swiper/react";
import type { SwiperProps } from "swiper/react";

import "swiper/css";

import { deepmerge } from "@mui/utils";

interface CarouselProps {
  children: JSX.Element | JSX.Element[];
  config?: SwiperProps;
}

const Carousel: FC<CarouselProps> = ({ children, config = {} }) => {
  const globalConfig: SwiperProps = deepmerge(
    {
      spaceBetween: 16,
      grabCursor: true,
      centerInsufficientSlides: true,
      breakpoints: {
        0: {
          slidesOffsetBefore: 16,
          slidesOffsetAfter: 16,
        },
        600: {
          slidesOffsetBefore: global.window
            ? (window?.innerWidth - 600) / 2 + 24
            : 0,
          slidesOffsetAfter: global.window
            ? (window?.innerWidth - 600) / 2 + 24
            : 0,
        },
        900: {
          slidesOffsetBefore: global.window
            ? (window?.innerWidth - 900) / 2 + 24
            : 0,
          slidesOffsetAfter: global.window
            ? (window?.innerWidth - 900) / 2 + 24
            : 0,
        },
        1200: {
          slidesOffsetBefore: global.window
            ? (window?.innerWidth - 1200) / 2 + 24
            : 0,
          slidesOffsetAfter: global.window
            ? (window?.innerWidth - 1200) / 2 + 24
            : 0,
        },
        1536: {
          slidesOffsetBefore: global.window
            ? (window?.innerWidth - 1536) / 2 + 24
            : 0,
          slidesOffsetAfter: global.window
            ? (window?.innerWidth - 1536) / 2 + 24
            : 0,
        },
      },
    },
    config,
  );

  return <Swiper {...globalConfig}>{children}</Swiper>;
};
export default Carousel;
