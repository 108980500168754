import { FC } from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import { TESTIMONIALS } from "./config";

import Heading from "sections/landingPage/Heading";

import Tweets from "components/Tweets";

const Testimonials: FC = () => {
  return (
    <Box sx={{ my: { xs: 4, md: 10, lg: 14 } }}>
      <Container>
        <Heading
          heading="Testimonials"
          subheading="We bring client satisfaction to the next level. Check out what they say about us."
          boxProps={{
            sx: {
              mb: { xs: 6, md: 12 },
              textAlign: "center",
              mx: "auto",
            },
          }}
        />
      </Container>

      <Tweets items={TESTIMONIALS} />
    </Box>
  );
};

export default Testimonials;
