import { FC } from "react";

import Image from "next/legacy/image";
import { SwiperSlide } from "swiper/react";
import type { SwiperProps } from "swiper/react";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { STORES } from "sections/landingPage/TopDrops/config";

import Carousel from "components/Carousel";
import CarouselArrows from "components/Carousel/Arrows";
import CustomButton from "components/CustomButton";

import styles from "./styles";

interface TopStoresMobileProps {
  activeIndex: number;
  handleIndexChange: (index: number) => void;
}

const TopStoresMobile: FC<TopStoresMobileProps> = ({
  activeIndex,
  handleIndexChange,
}) => {
  const config: SwiperProps = {
    spaceBetween: 16,
    loop: true,
    slidesPerView: "auto",
    onRealIndexChange: ({ realIndex }) => {
      handleIndexChange(realIndex);
    },
  };

  return (
    <Carousel config={config}>
      <span slot="container-start">
        <Container sx={{ pt: 1 }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h3" align="left" mb={2}>
              {STORES[activeIndex].name}
            </Typography>
            <CarouselArrows />
          </Box>
        </Container>
      </span>
      <>
        {STORES.map((store, index) => (
          <SwiperSlide key={index} style={{ width: "auto" }}>
            <CustomButton
              simple
              sx={(theme) =>
                styles.itemWrapper(theme, {
                  isActive: index === activeIndex,
                })
              }
              onClick={() => {
                handleIndexChange(index);
              }}
            >
              <Box sx={styles.itemImage}>
                <Image src={store.bannerImage} layout="fill" />
              </Box>
            </CustomButton>
          </SwiperSlide>
        ))}
      </>
    </Carousel>
  );
};

export default TopStoresMobile;
