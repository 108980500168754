import { Theme } from "@mui/material/styles";
import { SystemStyleObject } from "@mui/system";

const topStoresCarouselStyles = {
  carouselItem: (): SystemStyleObject<Theme> => ({
    display: "flex",
    placeItems: "center",
    justifyContent: "center",
  }),
  icon: (
    theme: Theme,
    { scale }: { scale: number },
  ): SystemStyleObject<Theme> => ({
    width: "100%",
    display: "block",
    color: theme.palette.primary.main,
    fontSize: `${40 * scale}px`,

    [theme.breakpoints.up("lg")]: {
      fontSize: `${50 * scale}px`,
    },
  }),
};

export default topStoresCarouselStyles;
