import { Theme } from "@mui/material/styles";
import { SystemStyleObject } from "@mui/system";

const BOX_WIDTH_MOBILE = 100;

const topStoresMobileStyles = {
  itemWrapper: (
    _: Theme,
    { isActive }: { isActive: boolean },
  ): SystemStyleObject<Theme> => ({
    display: "grid",
    backgroundColor: "none",
    border: "none",
    p: 0,
    m: 0,
    overflow: "hidden",
    position: "relative",

    height: BOX_WIDTH_MOBILE,
    width: `${BOX_WIDTH_MOBILE}px !important`,
    filter: isActive ? "brightness(1)" : "brightness(0.5)",

    "&:hover, &:focus, &:active": {
      filter: "brightness(1.1)",
    },
  }),
  itemImage: (): SystemStyleObject<Theme> => ({
    "& > span": {
      width: `${BOX_WIDTH_MOBILE * 3.28}px !important`,
    },
  }),
};

export default topStoresMobileStyles;
