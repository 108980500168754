import NextImage, { ImageProps } from "next/image";

import { FC, useEffect, useState } from "react";

import isNumber from "lodash/isNumber";

import Box, { BoxProps } from "@mui/material/Box";

import Loader from "components/Loader";

import styles from "./styles";

interface ImageNextProps extends Omit<ImageProps, "alt"> {
  src: string;
  alt?: string;
  errorImg?: string;
  bgColor?: string;
  width?: number;
  height?: number;
  boxProps?: BoxProps;
}

const ImageNext: FC<ImageNextProps> = ({
  src,
  alt = "",
  errorImg = "",
  bgColor,
  boxProps = {},
  ...props
}) => {
  const [imgSrc, setSrc] = useState("");

  useEffect(() => {
    const onLoad = () => {
      setSrc(src);
    };

    const onError = () => {
      setSrc(errorImg);
    };

    const img = new Image();
    img.src = src;
    img.addEventListener("load", onLoad);
    img.addEventListener("error", onError);
    return () => {
      img.removeEventListener("load", onLoad);
      img.removeEventListener("error", onError);
    };
  }, [src, errorImg]);

  return imgSrc ? (
    <Box
      {...boxProps}
      sx={[
        styles.image,
        (theme) => (bgColor ? styles.background(theme, { bgColor }) : {}),
      ]}
    >
      <NextImage loading="lazy" {...props} alt={alt || imgSrc} src={imgSrc} />
    </Box>
  ) : (
    <Box
      display="flex"
      alignItems="center"
      sx={[
        {
          width: "100%",
          maxWidth: "100vw",
          ...(isNumber(props?.width) && isNumber(props?.height)
            ? { aspectRatio: `${props.width} / ${props.height}` }
            : {}),
        },
        ...(Array.isArray(boxProps.sx)
          ? boxProps.sx || {}
          : [boxProps.sx || {}]),
      ]}
    >
      <Loader center size={30} />
    </Box>
  );
};

export default ImageNext;
